<template>
    <section class="container">
        <el-row>
            <el-select v-model="provinceValue" placeholder="请选择" @change="selectProvimce">
                <el-option v-for="(item,index) of provincearr" :key="index" :label="item.name" :value="item.id"></el-option>
            </el-select>
            <el-select v-model="cityValue" placeholder="请选择" @change="selectcity">
                <el-option v-for="(item,index) of cityarr" :key="index" :label="item.name" :value="item.id"></el-option>
            </el-select>
            <el-select placeholder="请选择" v-model="RegionValue">
                <el-option v-for="(item,index) of regionarr" :key="index" :label="item.name" :value="item.id"></el-option>
            </el-select>
        </el-row>
    </section>
</template>
<script>
import { province } from "../utils/map";
export default {
    created() {
        this.provincearr = province;
    },
    data() {
        return {
            arr: [1, 3, 4],
            province: [],
            cityarr: [],
            regionarr: [],
            provinceValue: "",
            cityValue: "",
            RegionValue: "",
        };
    },
    mounted() {},
    methods: {
        selectProvimce(id) {
            this.cityarr = [];
            this.regionarr = [];
            this.cityValue = "";
            this.RegionValue = "";
            for (let item of this.provincearr) {
                if (id == item.id) {
                    this.cityarr = item.children;
                }
            }
        },
        selectcity(id) {
            this.regionarr = [];
            this.RegionValue = "";
            for (let item of this.cityarr) {
                if (id == item.id) {
                    this.regionarr = item.children;
                }
            }
        },

        //获取省的数据
        getProvinceValue() {
            let res = "";
            let obj = this.provincearr.find((item) => {
                return item.id == this.provinceValue;
            });
            if (!this.isEmpty(obj)) {
                res = obj.name;
            }
            return res;
        },

        //获取市
        getCityValue() {
            let res = "";
            let obj = this.cityarr.find((item) => {
                return item.id == this.cityValue;
            });
            if (!this.isEmpty(obj)) {
                res = obj.name;
            }
            return res;
        },
        //获取区
        getRegionValue() {
            let res = "";
            let obj = this.regionarr.find((item) => {
                return item.id == this.RegionValue;
            });
            if (!this.isEmpty(obj)) {
                res = obj.name;
            }
            return res;
        },

        //设置省的数据
        setProvinceValue(pValue) {
            for (let item of this.provincearr) {
                if (pValue == item.name) {
                    this.provinceValue = item.id;
                    this.selectProvimce(this.provinceValue);
                }
                // console.log(pValue);
            }
        },
        //设置市的数据
        setCityValue(pValue) {
            for (let item of this.cityarr) {
                if (pValue == item.name) {
                    this.cityValue = item.id;
                    this.selectcity(this.cityValue);
                }
                // console.log(pValue);
            }
        },
        //设置区的数据
        setRegionValue(pValue) {
            for (let item of this.regionarr) {
                if (pValue == item.name) {
                    this.RegionValue = item.id;
                }
                // console.log(pValue);
            }
        },
    },
};
</script>
<style lang='scss' scoped>
.container {
    ::v-deep .el-input__inner {
        height: 32px;
        line-height: 32px;
        padding-left: 6px;
    }

    ::v-deep .el-input__icon {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    ::v-deep .el-input {
        width: 120px !important;
    }

    ::v-deep .el-input__suffix {
        right: 0px;
    }
}
</style>