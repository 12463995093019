// 校区新增或者编辑
<template>
  <div class="campus_edit">
    <Layout :showLoading="showLoading">
      <template v-slot:content>
        <div class="campus_edit_content">
          <!-- 顶部的部分 -->
          <el-scrollbar class="scroll_view">
            <div class="top_info">
              <!-- 学校名称 -->
              <div class="com_top_dv margin_top_20">
                <div class="com_left_w">
                  <span class="red_icon">*</span>
                  <span>学校名称：</span>
                </div>
                <el-input clearable v-model="schoolName" placeholder="请输入学校名称" maxlength="20"></el-input>
                <!-- 学校编码 -->
                <span v-if="type == 2">
                  <span class="margin_left_30"></span>
                  <span>学校编码：{{ schoolId }}</span>
                  <span class="margin_left_10">
                    <el-button size="mini" type="primary" @click="copySchool">复制</el-button>
                  </span>
                  <span class="red_text">注：学校编码为学校唯一标识,不可修改</span>
                </span>
              </div>

              <!-- 校长联系手机号 -->
              <div class="com_top_dv margin_top_20">
                <div class="com_left_w">
                  <span class="red_icon">*</span>
                  <span>校长手机号：</span>
                </div>
                <el-input onkeyup="value=value.replace(/[^\d]/g,'')" clearable v-model="contactsPhone" placeholder="请输入联系手机号" maxlength="11"></el-input>
                <span class="red_icon margin_left_14">*</span>
                <span>校长姓名：</span>
                <el-input clearable v-model="contactsName" placeholder="请输入联系人姓名" maxlength="20"></el-input>
              </div>

              <!-- 招生手机号,招生负责人姓名 -->
              <div class="com_top_dv margin_top_20">
                <div class="com_left_w">
                  <span class="red_icon">*</span>
                  <span>招生手机号：</span>
                </div>
                <el-input onkeyup="value=value.replace(/[^\d]/g,'')" clearable v-model="recruitPhone" placeholder="请输入联系手机号" maxlength="11"></el-input>
                <span class="red_icon margin_left_14">*</span>
                <span>招生负责人姓名：</span>
                <el-input clearable v-model="recruitName" placeholder="请输入联系人姓名" maxlength="20"></el-input>
              </div>

              <!-- 校区地址 -->
              <div class="com_top_dv margin_top_20">
                <div class="com_left_w">
                  <span class="red_icon">*</span>
                  <span>校区地址：</span>
                </div>
                <AddressPicker ref="address_picker"></AddressPicker>
              </div>

              <!-- 详细地址 -->
              <div class="com_top_dv margin_top_20 details_address">
                <div class="com_left_w">
                  <span>详细地址：</span>
                </div>
                <el-input clearable v-model="address" placeholder="请输入详细地址" maxlength="100"></el-input>
              </div>

              <!-- 校区logo -->
              <div class="com_top_dv margin_top_30 com_bom_dv">
                <span class="com_left_w">校区logo：</span>
                <div class="upload_dv">
                  <span class="margin_bom_12">上传图片的最佳尺寸：300像素*300像素，其他尺寸会影响页<br />效果，格式png，jpeg，jpg。大小不超过2M</span>
                  <el-upload action="#" :show-file-list="false" :before-upload="beforeLogoUpload">
                    <!-- 回显的logo图片 -->
                    <el-image v-if="logoUrl" style="width: 300px; height: 300px; border-radius: 4px" :src="logoUrl" fit="cover"></el-image>
                    <el-button v-else size="small" type="primary">点击上传</el-button>
                  </el-upload>
                </div>
              </div>

              <!-- 联系人二维码 -->
              <div class="com_top_dv margin_top_30 com_bom_dv">
                <span class="com_left_w">招生负责人二维码：</span>
                <div class="upload_dv">
                  <span class="margin_bom_12">上传图片的最佳尺寸：300像素*300像素，其他尺寸会影响页<br />效果，格式png，jpeg，jpg。大小不超过2M</span>
                  <el-upload action="#" :show-file-list="false" :before-upload="beforeCodeUpload">
                    <!-- 回显的logo图片 -->
                    <el-image v-if="codeUrl" style="width: 300px; height: 300px; border-radius: 4px" :src="codeUrl" fit="cover"></el-image>
                    <el-button v-else size="small" type="primary">点击上传</el-button>
                  </el-upload>
                </div>
              </div>
            </div>
          </el-scrollbar>

          <!-- 底部的取消和下一步 -->
          <div class="bom_bt_action">
            <!-- 取消 com_bt_action -->
            <el-button size="medium" class="bt_cancel" @click.stop="doCancel">取&nbsp;&nbsp;消</el-button>

            <!-- 下一步 -->
            <el-button size="medium" class="bt_next" type="primary" :loading="loading" @click.stop="doNext" v-html="commitMsg"></el-button>
          </div>

          <!--  上传组件 -->
          <!-- <UpLoadingView v-if="showUploadView"></UpLoadingView> -->
        </div>
      </template>
    </Layout>
  </div>
</template>
<script>
import Layout from "@/components/Layout.vue";
import AddressPicker from "@/components/AddressPicker.vue";
export default {
  //   name: "CampusEdit",
  data() {
    return {
      //是否显示加载loading -1为显示加载
      showLoading: 1,

      //是否显示上传loading
      // showUploadView: false,

      //学校名称
      schoolName: "",

      //校长联系手机号
      contactsPhone: "",

      //校长联系人姓名
      contactsName: "",

      //招生手机号
      recruitPhone: "",

      //招生负责任姓名
      recruitName: "",

      //详细地址
      address: "",
      //logo的图片地址
      logoUrl: "",
      //二维码的图片地址
      codeUrl: "",
      //上传的logo图片文件
      logoFile: null,
      //上传的二维码图片文件
      codeFile: null,

      //是否显示提交的button加载框
      loading: false,

      //新增还是编辑进入的标识 1新增 2编辑
      type: -1,

      //学校ID
      schoolId: "",

      // 新建显示下一步  编辑显示保存
      commitMsg: "",

      //编辑进入时候获取加盟状态
      state: "",
    };
  },
  mounted() {
    //初始化数据
    this.initData();
  },

  methods: {
    //初始化数据
    initData() {
      //获取type  1表示新增 2表示编辑
      this.type = this.$route.query.type;

      if (this.type == 2) {
        //表示是点击编辑进入的当前页面,获取相信信息进行数据的回显
        let schoolInfo = JSON.parse(this.$route.query.schoolInfo);

        //学校名称
        this.schoolName = schoolInfo.school_name;

        //校长联系手机号
        this.contactsPhone = schoolInfo.principal_tel;

        //校长联系人姓名
        this.contactsName = schoolInfo.principal_name;

        //招生人手机号
        this.recruitPhone = schoolInfo.contacts_tel;

        //招生人姓名
        this.recruitName = schoolInfo.contacts_name;

        //详细地址
        this.address = schoolInfo.address;

        //LOGO图
        this.logoUrl = schoolInfo.school_logo;

        //LOGO图
        this.codeUrl = schoolInfo.contacts_code;

        //学校ID
        this.schoolId = schoolInfo.school_id;

        //同步省
        this.$refs.address_picker.setProvinceValue(schoolInfo.province);

        //同步市
        this.$refs.address_picker.setCityValue(schoolInfo.city);

        //同步区
        this.$refs.address_picker.setRegionValue(schoolInfo.county);

        //编辑时获取加盟状态
        this.state = this.$route.query.state;
        if (this.state == -3) {
          //待完善状态
          this.commitMsg = "下一步";
        } else {
          this.commitMsg = "保&nbsp;&nbsp;存";
        }
      } else {
        //新增时下一步
        this.commitMsg = "下一步";
      }
    },

    //取消
    doCancel() {
      this.$router.go(-1);
    },

    //下一步
    doNext() {
      //判断输入内容的完整性
      if (this.isEmpty(this.schoolName)) {
        this.showWarnMsg("请输入学校名称");
        return;
      }

      if (this.isEmpty(this.contactsPhone)) {
        this.showWarnMsg("请输入校长手机号");
        return;
      }

      if (!this.testPhone(this.contactsPhone)) {
        this.showWarnMsg("校长手机号格式不对");
        return;
      }

      if (this.isEmpty(this.contactsName)) {
        this.showWarnMsg("请输入校长姓名");
        return;
      }

      if (this.regExp(this.contactsName)) {
        this.showWarnMsg("输入的校长姓名不能包含数字和特殊字符");
        return;
      }

      if (this.isEmpty(this.recruitPhone)) {
        this.showWarnMsg("请输入招生手机号");
        return;
      }

      if (!this.testPhone(this.recruitPhone)) {
        this.showWarnMsg("招生负责人手机号格式不对");
        return;
      }

      if (this.isEmpty(this.recruitName)) {
        this.showWarnMsg("请输入招生负责任姓名");
        return;
      }

      if (this.regExp(this.recruitName)) {
        this.showWarnMsg("输入的招生负责任姓名不能包含数字和特殊字符");
        return;
      }

      if (this.type == 1) {
        //新增校区接口
        this.addSchoolHttp();
      } else {
        //编辑校区接口
        this.editSchoolHttp();
      }
    },

    //新增校区接口
    addSchoolHttp() {
      //校区地址,获取校区地址的省市区
      let province = this.$refs.address_picker.getProvinceValue();
      let city = this.$refs.address_picker.getCityValue();
      let Region = this.$refs.address_picker.getRegionValue();
      if (this.isEmpty(province)) {
        this.showWarnMsg("请选择省的数据");
        return;
      }
      if (this.isEmpty(city)) {
        this.showWarnMsg("请选择市的数据");
        return;
      }
      if (this.isEmpty(Region)) {
        this.showWarnMsg("请选择区的数据");
        return;
      }

      this.loading = true;

      //新增校区接口
      let param = new FormData();
      param.append("type", 1);
      param.append("school_name", this.schoolName);

      //校长手机号和校长姓名
      param.append("principal_tel", this.contactsPhone);
      param.append("principal_name", this.contactsName);

      //招生联系人姓名和手机号
      param.append("contacts_tel", this.recruitPhone);
      param.append("contacts_name", this.recruitName);

      param.append("province", province);
      param.append("city", city);
      param.append("county", Region);
      if (!this.isEmpty(this.address)) {
        param.append("address", this.address);
      }
      param.append("uid", this.getUserInfo().id);
      param.append("token", this.getUserInfo().token);

      //logo图片
      if (this.logoFile != null) {
        param.append("logo", this.logoFile);
      }
      //联系人二维码图片
      if (this.codeFile != null) {
        param.append("code", this.codeFile);
      }
      this.$http
        .fetchFormDataPost(this.$api.SCHOOL_MANAGER, param)
        .then((res) => {
          //关闭弹框
          this.closeLoading();
          if (res.data.state == 200) {
            //新增
            let sid = res.data.data.school_id;

            //上传成功
            this.$router.push({
              path: "/Home/ProjectMode",
              query: { type: this.type, sid: sid },
            });
          } else {
            this.showErrorMsg(this.getMesage(res.data.state));
          }
        })
        .catch((err) => {
          this.closeLoading();
          this.showErrorMsg("服务器请求异常,上传失败");
        });
    },

    //编辑校区接口
    editSchoolHttp() {
      //判断是否修改了数据,修改了数据就进行上传
      if (!this.hasUpdateSchoolInfo()) {
        // 进入到下个校区详情页面
        if (this.state == -3) {
          //待完善状态直接进入到编辑模式
          //上传成功
          this.$router.push({
            path: "/Home/ProjectMode",
            query: {
              type: this.type,
              sid: this.schoolId,
              state: this.state,
              mode: "ProjectMode",
            },
          });
        } else {
          this.showWarnMsg("未修改校区数据,无需进行保存操作");
        }
        return;
      }

      //校区地址,获取校区地址的省市区
      let province = this.$refs.address_picker.getProvinceValue();
      let city = this.$refs.address_picker.getCityValue();
      let Region = this.$refs.address_picker.getRegionValue();
      if (this.isEmpty(province)) {
        this.showWarnMsg("请选择省的数据");
        return;
      }
      if (this.isEmpty(city)) {
        this.showWarnMsg("请选择市的数据");
        return;
      }
      if (this.isEmpty(Region)) {
        this.showWarnMsg("请选择区的数据");
        return;
      }

      this.loading = true;

      //编辑校区接口
      let param = new FormData();
      param.append("type", 4);
      param.append("school_id", this.schoolId);
      param.append("school_name", this.schoolName);

      //校长手机号和校长姓名
      param.append("principal_tel", this.contactsPhone);
      param.append("principal_name", this.contactsName);

      //招生联系人姓名和手机号
      param.append("contacts_tel", this.recruitPhone);
      param.append("contacts_name", this.recruitName);

      param.append("province", province);
      param.append("city", city);
      param.append("county", Region);
      if (!this.isEmpty(this.address)) {
        param.append("address", this.address);
      }
      param.append("uid", this.getUserInfo().id);
      param.append("token", this.getUserInfo().token);

      //logo图片
      if (this.logoFile != null) {
        param.append("logo", this.logoFile);
      }

      //联系人二维码图片
      if (this.codeFile != null) {
        param.append("code", this.codeFile);
      }

      this.$http
        .fetchFormDataPost(this.$api.SCHOOL_MANAGER, param)
        .then((res) => {
          //关闭弹框
          this.closeLoading();
          // console.log("返回的数据是:", res);
          if (res.data.state == 200) {
            if (this.state == -3) {
              //待完善状态直接进入到编辑模式
              //新增
              let sid = res.data.data.school_id;
              //上传成功
              this.$router.push({
                path: "/Home/ProjectMode",
                query: {
                  type: this.type,
                  sid: sid,
                  state: this.state,
                  mode: "ProjectMode",
                },
              });
            } else {
              //其它模式直接保存后返回列表
              //编辑
              this.showSuccessMsg("保存成功");
              //返回
              this.doCancel();
            }
          } else {
            this.showErrorMsg(this.getMesage(res.data.state));
          }
        })
        .catch((err) => {
          this.closeLoading();
          this.showErrorMsg("服务器请求异常,上传失败");
        });
    },
    //logo的上传限制
    beforeLogoUpload(file) {
      const isImgType = file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/png，jpeg，jpg";

      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isImgType) {
        this.$message.error("上传图片只能是 png，jpeg，jpg格式!");
        return;
      }

      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
        return;
      }

      //记录上传的logo文件
      this.logoFile = file;
      if (window.FileReader) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = (e) => {
          //同步下需要显示的logo图片
          this.logoUrl = e.target.result;
        };
      }

      return isImgType && isLt2M;
    },

    //二维码的上传限制
    beforeCodeUpload(file) {
      const isImgType = file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/png，jpeg，jpg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isImgType) {
        this.$message.error("上传图片只能是 png，jpeg，jpg格式!");
        return;
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
        return;
      }

      //记录上传的二维码文件
      this.codeFile = file;
      if (window.FileReader) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = (e) => {
          //同步下需要显示的logo图片
          this.codeUrl = e.target.result;
        };
      }

      return isImgType && isLt2M;
    },
    //关闭Loading的方法
    closeLoading() {
      //上传成功后关闭loading
      if (!this.isEmpty(this.loading)) {
        this.loading = false;
      }
    },
    //复制学校编码
    copySchool() {
      var temp = document.createElement("textarea");
      temp.value = this.schoolId;
      document.body.appendChild(temp);
      temp.select(); // 选择对象
      document.execCommand("Copy"); // 执行浏览器复制命令
      temp.style.display = "none";
      this.showSuccessMsg("编码已复制");
    },

    //判断是否更改了校区信息
    hasUpdateSchoolInfo() {
      let res = false;
      //获取传递的学校信息
      let schoolInfo = JSON.parse(this.$route.query.schoolInfo);
      //判断页面信息是否有修改
      if (
        this.schoolName != schoolInfo.school_name ||
        this.contactsPhone != schoolInfo.principal_tel ||
        this.contactsName != schoolInfo.principal_name ||
        this.recruitPhone != schoolInfo.contacts_tel ||
        this.recruitName != schoolInfo.contacts_name ||
        this.$refs.address_picker.getProvinceValue() != schoolInfo.province ||
        this.$refs.address_picker.getCityValue() != schoolInfo.city ||
        this.$refs.address_picker.getRegionValue() != schoolInfo.county ||
        this.address != schoolInfo.address ||
        !this.isEmpty(this.logoFile) ||
        !this.isEmpty(this.codeFile)
      ) {
        res = true;
      }

      return res;
    },
  },
  computed: {},
  components: { Layout, AddressPicker },
};
</script>
<style lang="scss" scoped>
.campus_edit {
  height: calc(100vh - 80px);
  .campus_edit_content {
    height: 100%;
    .com_left_w {
      width: 120px;
    }

    .com_top_dv {
      display: flex;
      align-items: center;

      .red_text {
        margin-left: 10px;
        color: red;
        font-size: 12px;
        align-items: center;
      }
    }

    .com_bom_dv {
      align-items: flex-start;
    }

    .upload_dv {
      display: flex;
      flex-direction: column;
    }

    ::v-deep .el-input__inner {
      height: 28px;
      line-height: 28px;
      padding-left: 6px;
    }

    ::v-deep .el-input__icon {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    ::v-deep .el-input {
      width: 200px;
      font-size: 12px;
    }

    ::v-deep .el-input__suffix {
      right: 0px;
    }

    //顶部的操作栏部分
    //滚动部分
    .scroll_view {
      height: calc(100vh - 76px - 80px);
      .top_info {
        // flex: 1;
        margin-left: 20px;

        //详细地址
        .details_address {
          ::v-deep .el-input {
            width: 360px;
            font-size: 12px;
          }
        }
      }
    }

    ::v-deep .el-scrollbar__wrap {
      overflow-y: auto;
      overflow-x: hidden;
    }

    .bom_bt_action {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
      margin-bottom: 20px;

      .bt_cancel,
      .bt_next {
        width: 120px;
        // color: white;
        // display: flex;
        // align-items: center;
        // justify-content: center;
        // font-size: $tv_base_size;
      }

      .bt_next {
        margin-left: 200px;
        // background: #409eff;
      }
    }
  }
}

//隐藏滚动栏
// .campus_edit::-webkit-scrollbar {
//     display: none; /* Chrome Safari */
// }
</style>
